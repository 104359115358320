import PropTypes from 'prop-types';
import React from 'react';

import CollapsibleText from 'core/components/CollapsibleText.js';
import {renderTemplate} from 'template.js';

export default class FeeInfo extends React.Component {
    static propTypes = {
        consultationFeeMessage: PropTypes.string.isRequired,
        procedureFeeMessage: PropTypes.string.isRequired,
        profileName: PropTypes.string.isRequired,
    };

    render() {
        const {
            consultationFeeMessage,
            procedureFeeMessage,
            profileName,
        } = this.props;
        return (
            <>
                <h2>{`Consultation fees for ${profileName}`}</h2>
                <CollapsibleText
                    expandText="Read more"
                    maxWords={20}
                    renderHtml={true}
                    text={renderTemplate(consultationFeeMessage, {
                        name: profileName,
                    })}
                />
                <hr />
                <h2>{`Procedure fees for ${profileName}`}</h2>
                <CollapsibleText
                    expandText="Read more"
                    maxWords={15}
                    renderHtml={true}
                    text={renderTemplate(procedureFeeMessage, {
                        name: profileName,
                    })}
                />
            </>
        );
    }
}
