import PropTypes from 'prop-types';
import React from 'react';

import Avatar from 'core/components/Avatar.js';
import CollapsibleText from 'core/components/CollapsibleText.js';

export default class Answer extends React.Component {
    static propTypes = {
        answer: PropTypes.shape({
            agrees: PropTypes.number,
            body: PropTypes.string.isRequired,
            communities: PropTypes.array,
            id: PropTypes.number,
            question: PropTypes.string,
            subject: PropTypes.string.isRequired,
            thanks: PropTypes.number,
            url: PropTypes.string.isRequired,
        }),
        avatar: PropTypes.shape({
            avatar: PropTypes.string,
            initials: PropTypes.string,
            profileId: PropTypes.number.isRequired,
        }),
    };

    render() {
        const {
            answer,
            avatar: {avatar, initials, profileId},
        } = this.props;
        const {body, question, subject, url} = answer;
        return (
            <li>
                <h2>
                    <a href={url}>{subject}</a>
                </h2>
                {question && (
                    <CollapsibleText
                        maxWords={30}
                        renderHtml={true}
                        text={question}
                    />
                )}
                <div>
                    <Avatar
                        avatar={avatar}
                        initials={initials}
                        profileId={profileId}
                    />
                    <CollapsibleText
                        maxWords={30}
                        renderHtml={true}
                        text={body}
                    />
                </div>
            </li>
        );
    }
}
