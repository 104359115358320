import React from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobXPropTypes} from 'mobx-react';

import Icon from 'core/components/Icon.js';
import Link from 'core/components/Link.js';

export default class List extends React.Component {
    static propTypes = {
        customClass: PropTypes.string,
        children: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                displayName: PropTypes.string,
                url: PropTypes.string,
            }),
        ).isRequired,
        moreUrl: PropTypes.string,
        title: PropTypes.string.isRequired,
    };

    render() {
        const {customClass, children, title, moreUrl} = this.props;

        if (!children.length) {
            return null;
        }

        return (
            <section className={customClass}>
                <h2>
                    {title}
                    {moreUrl && (
                        <Link label={`More ${title}`} href={moreUrl}>
                            {'More'}
                        </Link>
                    )}
                </h2>
                <ul>{children}</ul>
            </section>
        );
    }
}
