import {PropTypes as MobXPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {observer} from 'mobx-react';

import http from 'http.js';

import autobind from 'common/decorators/autobind.js';
import {PromotionsStoreContext} from 'core/stores/RootStore.js';
import FeeInfo from 'professional/components/FeeInfo.js';
import FundInfo from 'professional/components/FundInfo.js';
import FundSelection from 'professional/components/FundSelection.js';
import NibInfo from 'professional/components/NibInfo';
import PractitionerFeeComments from 'professional/components/PractitionerFeeComments.js';
import PromotedProfilesBanner from 'professional/components/PromotedProfilesBanner.js';
import TeledocOptions from 'promotions/components/TeledocOptions.js';

export const VER_1 = 'version-1';
export const VER_2 = 'version-2';
export const NIB_ID = 41;

export function feesQuestion(isReferrals) {
    return isReferrals
        ? 'Does your patient have private health insurance?'
        : 'Do you have private health insurance?';
}

export default
@observer
class Fees extends React.Component {
    static contextType = PromotionsStoreContext;

    static propTypes = {
        gapSchemeResults: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.shape({
                avatar: PropTypes.string,
                id: PropTypes.number,
                displayName: PropTypes.string,
                profileUrl: PropTypes.string,
                specialties: PropTypes.arrayOf(PropTypes.object),
            }),
        ).isRequired,
        gapSchemeResultsCount: PropTypes.number,
        globalFund: PropTypes.shape({
            'health_fund_id': PropTypes.number,
            'gap_scheme_id': PropTypes.number,
        }),
        isEnhanced: PropTypes.bool,
        isMobile: PropTypes.bool,
        isMobileOrTablet: PropTypes.bool,
        isReferrals: PropTypes.bool.isRequired,
        isSpecialist: PropTypes.bool.isRequired,
        localityId: PropTypes.number,
        profileFeeComments: PropTypes.string,
        profileId: PropTypes.number.isRequired,
        profileName: PropTypes.string.isRequired,
        scrollToSection: PropTypes.oneOf(['procedure_fees']),
        selectedFund: PropTypes.object,
        skin: PropTypes.string,
        specialtyId: PropTypes.number,
        show: PropTypes.string.isRequired,
        staticUrl: PropTypes.string.isRequired,
        updateSelectedFund: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            feeInfo: undefined,
            fundInfo: undefined,
            loading: true,
        };
        this.feeInfoKeys = [
            'NOT_INSURED_CONSULTATION_FEES_INFO',
            'INSURED_CONSULTATION_FEES_INFO',
            'NOT_INSURED_PROCEDURE_FEES_INFO',
            'INSURED_PROCEDURE_FEES_INFO',
            'PRACTITIONER_FEE_COMMENTS_TOOLTIP',
        ];
        this.fundInfoKeys = [
            'GAP_SCHEME_GRAPH_TITLE',
            'GAP_SCHEME_PARTICIPATION_TOOLTIP',
            'GAP_SCHEME_PARTICIPATION_TOOLTIP_PRO',
        ];
        this.shouldScroll = false;
    }

    async componentDidMount() {
        const {scrollToSection} = this.props;
        // Can move to Overview tab to avoid waiting for it here
        const customText = await this.loadCustomText();

        this.shouldScroll = !!scrollToSection;
        this.setState(this.getInitialState(customText));
    }

    getInitialState(customText) {
        return {
            feeInfo: this.getInfo(this.feeInfoKeys, customText),
            fundInfo: this.getInfo(this.fundInfoKeys, customText),
            loading: false,
        };
    }

    getInfo(keys, object) {
        const info = {};
        for (const key of keys) {
            info[key] = object[key];
        }
        return info;
    }

    async loadCustomText() {
        const url = '/api/core/v1/custom-text/';
        const keys = this.feeInfoKeys.concat(this.fundInfoKeys);
        return http.get({url, data: {key: keys}});
    }

    @autobind
    scrollToReference(elementRef) {
        if (this.shouldScroll && elementRef) {
            elementRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
            this.shouldScroll = false;
        }
    }

    renderNibInfo() {
        const {isEnhanced} = this.props;
        const {
            healthFundStore: {healthFund},
            paramStore: {client},
        } = this.context.rootStore;
        if (healthFund?.id === NIB_ID && !client && !isEnhanced) {
            return <NibInfo healthFund={healthFund.id} />;
        }
        return null;
    }

    renderFundInfo(pracFeeTooltip) {
        const {fundInfo} = this.state;
        const {
            isReferrals,
            isSpecialist,
            profileId,
            profileName,
            scrollToSection,
            show,
            staticUrl,
        } = this.props;
        const {healthFund} = this.context.rootStore.healthFundStore;
        if (healthFund?.id) {
            return (
                <FundInfo
                    healthFund={healthFund}
                    info={fundInfo}
                    isReferrals={isReferrals}
                    isSpecialist={isSpecialist}
                    onProcedureFeeMount={
                        scrollToSection === 'procedure_fees'
                            ? this.scrollToReference
                            : null
                    }
                    pracFeeCommentsTooltip={pracFeeTooltip}
                    profileId={profileId}
                    profileName={profileName}
                    show={show}
                    staticUrl={staticUrl}
                />
            );
        }
        return null;
    }

    renderPractitionerFeeComments(pracFeeTooltip) {
        if (this.props.isSpecialist && this.props.profileFeeComments) {
            return (
                <PractitionerFeeComments
                    comments={this.props.profileFeeComments}
                    profileName={this.props.profileName}
                    titleTooltip={pracFeeTooltip}
                />
            );
        } else {
            return null;
        }
    }

    renderPromotions() {
        const {
            gapSchemeResults,
            gapSchemeResultsCount,
            globalFund,
            isMobile,
            isMobileOrTablet,
            localityId,
            profileId,
            specialtyId,
        } = this.props;
        const promotedProfilesBannerProps = {
            gapSchemeResults,
            gapSchemeResultsCount,
            globalFund,
            isMobile,
            isMobileOrTablet,
            localityId,
            profileId,
            specialtyId,
        };
        if (this.context.teledocPromotionAvailableOnSelectedFund) {
            return <TeledocOptions />;
        }

        // Render Promoted profiles banners in all other cases
        return <PromotedProfilesBanner {...promotedProfilesBannerProps} />;
    }

    renderFundSelection() {
        const {
            isEnhanced,
            isReferrals,
            isSpecialist,
            profileId,
            skin,
            updateSelectedFund,
        } = this.props;
        const {
            healthFundStore: {healthFund},
            paramStore: {client},
        } = this.context.rootStore;
        if (skin && !client) {
            return null;
        }
        return (
            <FundSelection
                formLabel={feesQuestion(isReferrals)}
                isEnhanced={isEnhanced}
                isReferrals={isReferrals}
                profileId={profileId}
                showNoInsurance={isSpecialist}
                updateSelectedFund={updateSelectedFund}
            />
        );
    }

    render() {
        const {feeInfo, loading} = this.state;
        const {isSpecialist, profileName} = this.props;
        const {healthFund} = this.context.rootStore.healthFundStore;
        if (loading) {
            return null;
        } else {
            // eslint-disable-next-line max-len
            const pracFeeTooltip = feeInfo.PRACTITIONER_FEE_COMMENTS_TOOLTIP;
            let consultationFeeMessage;
            let procedureFeeMessage;

            if (healthFund?.id) {
                // eslint-disable-next-line max-len
                consultationFeeMessage =
                    feeInfo.INSURED_CONSULTATION_FEES_INFO;
                procedureFeeMessage = feeInfo.INSURED_PROCEDURE_FEES_INFO;
            } else {
                // eslint-disable-next-line max-len
                consultationFeeMessage =
                    feeInfo.NOT_INSURED_CONSULTATION_FEES_INFO;
                procedureFeeMessage = feeInfo.NOT_INSURED_PROCEDURE_FEES_INFO;
            }

            return (
                <>
                    {this.renderPractitionerFeeComments(pracFeeTooltip)}
                    {this.renderFundSelection()}
                    {this.renderNibInfo()}
                    {isSpecialist && (
                        <>
                            {this.renderPromotions()}
                            <FeeInfo
                                consultationFeeMessage={consultationFeeMessage}
                                procedureFeeMessage={procedureFeeMessage}
                                profileName={profileName}
                            />
                        </>
                    )}
                    {this.renderFundInfo(pracFeeTooltip)}
                </>
            );
        }
    }
}
