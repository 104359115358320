import {observer} from 'mobx-react';
import React from 'react';

import analytics from 'analytics.js';
import IntegratedProfileApp from 'professional/components/IntegratedProfileApp.js';
import ProfileApp from 'professional/components/ProfileApp.js';
import {ProfileStoreContext} from 'core/stores/RootStore.js';
import query from 'query.js';

export default
@observer
class CombinedProfileApp extends React.Component {
    static contextType = ProfileStoreContext;

    componentDidMount() {
        this.context.updateStore({
            ...this.props,
            pageLoaded: true,
        });
        this.trackPractitionerDetailEvent();
    }

    async trackPractitionerDetailEvent() {
        const {healthFund} = this.context.rootStore.healthFundStore;
        analytics.track('practitionerDetail', {
            hsHealthFundId: healthFund?.id,
            practitionerId: this.props.profileId,
        });
    }

    render() {
        if (!this.context.pageLoaded) {
            return null;
        }
        return (
            <>
                {this.context.rootStore.paramStore.isClient ? (
                    <IntegratedProfileApp {...this.props} />
                ) : (
                    <ProfileApp {...this.props} />
                )}
            </>
        );
    }
}
