import React from 'react';
import PropTypes from 'prop-types';

import analytics from 'analytics.js';
import query from 'query.js';

import CollapsibleNumber from 'core/components/CollapsibleNumber.js';
import Icon from 'core/components/Icon.js';
import WebsiteLink from 'core/components/WebsiteLink.js';
import {HICAPS, TELEHEALTH} from 'core/constants';
import NextAvailableBadge from 'professional/components/NextAvailableBadge.js';

export default class ContactInfo extends React.Component {
    static propTypes = {
        client: PropTypes.string,
        fax: PropTypes.shape({
            number: PropTypes.string.isRequired,
            description: PropTypes.string,
        }),
        offersHicaps: PropTypes.bool,
        offersTelehealth: PropTypes.bool,
        phones: PropTypes.arrayOf(
            PropTypes.shape({
                number: PropTypes.string.isRequired,
                description: PropTypes.string,
            }),
        ),
        practice: PropTypes.object,
        practiceId: PropTypes.number,
        practiceName: PropTypes.string,
        practiceOrder: PropTypes.number.isRequired,
        profileId: PropTypes.number,
        showModal: PropTypes.func.isRequired,
        website: PropTypes.string,
    };

    getTitle(numberType) {
        let title = `Click to show ${numberType} number`;
        if (this.props.practiceName) {
            title += ` for ${this.props.practiceName}`;
        }
        return title;
    }

    handleClickEvent(type, phoneNumber) {
        const {practiceId, showModal} = this.props;
        if (type === 'phone') {
            if (phoneNumber) {
                showModal(practiceId, phoneNumber);
            } else {
                showModal(practiceId);
            }
        }
        this.trackClickEvent(type, phoneNumber);
    }

    trackClickEvent(type) {
        const {fax, practiceId, profileId} = this.props;

        if (!fax) {
            return;
        }

        if (type === 'fax') {
            analytics.track('practitionerFaxClick', {
                'health_fund': query.parse().health_fund,
                practitioner: profileId,
                'practice_position': practiceId,
                'phone_number': fax.number,
            });
        }
    }

    renderTelehealthBadge() {
        return (
            <div>
                <dt>
                    <Icon name="telehealth" />
                    {'Telehealth:'}
                </dt>
                <dd>{TELEHEALTH}</dd>
            </div>
        );
    }

    render() {
        const {
            client,
            fax,
            offersHicaps,
            offersTelehealth,
            practiceId,
            practiceOrder,
            phones,
            website,
        } = this.props;
        if (
            !phones?.length &&
            !fax &&
            !website &&
            !offersHicaps &&
            !offersTelehealth
        ) {
            return null;
        }

        return (
            <dl className="icon-list">
                <NextAvailableBadge
                    hasIcon={true}
                    practices={[this.props.practice]}
                />
                {offersTelehealth && this.renderTelehealthBadge()}
                {phones?.map((phone) => {
                    const {description, number} = phone;
                    if (!number) {
                        return;
                    }
                    return (
                        <div key={number}>
                            <dt>
                                <Icon name="phone" />
                                {'Phone:'}
                            </dt>
                            <dd aria-live="polite">
                                <CollapsibleNumber
                                    number={number}
                                    onClick={() =>
                                        this.handleClickEvent('phone', number)
                                    }
                                    title={this.getTitle('phone')}
                                    type="phone"
                                />
                                {description && <p>{description}</p>}
                            </dd>
                        </div>
                    );
                })}
                {fax?.number && (
                    <div>
                        <dt>
                            <Icon name="print" />
                            {'Fax:'}
                        </dt>
                        <dd aria-live="polite">
                            <CollapsibleNumber
                                number={fax.number}
                                onClick={() => this.handleClickEvent('fax')}
                                title={this.getTitle('fax')}
                                type="fax"
                            />
                            {fax.description && <p>{fax.description}</p>}
                        </dd>
                    </div>
                )}
                {!client && website && (
                    <div>
                        <dt>
                            <Icon name="externalLink" />
                            {'Website: '}
                        </dt>
                        <dd>
                            <WebsiteLink
                                href={website}
                                trackingData={{
                                    eventName: 'openPracticePositionWebsite',
                                    data: {
                                        'practice_position': practiceId,
                                    },
                                }}
                            />
                        </dd>
                    </div>
                )}
                {offersHicaps && (
                    <div>
                        <dt>
                            <Icon name="card" />
                            {'Hicaps:'}
                        </dt>
                        <dd>{`Offers ${HICAPS}`}</dd>
                    </div>
                )}
            </dl>
        );
    }
}
